import { constructQueryParamsFromObject } from "src/utils/utils";
import { ICenter, ICenterDistrictsRequest, ICenterRequest, ICentersWithStatsRequest, IDistrict } from "../models/Center";
import FetchError from "./errors";
import { IOrgStatistics, IOrgStatisticsRequest } from '../models/UserStatistics';

import { customFetchWithRetry } from "./shared";

export const getCenters = async (request: ICentersWithStatsRequest): Promise<ICenter[] | null> => {
  try {
    let requestUrl = `/org/${request.orgId}/center?${constructQueryParamsFromObject(request)}`;
    const response = await customFetchWithRetry(requestUrl, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getCenterWithStats = async (request: ICenterRequest): Promise<ICenter | null> => {
  try {
    const response = await customFetchWithRetry(`/org/${request.orgId}/center/${request.centerId}?${constructQueryParamsFromObject(request)}`, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getCenterDistricts = async (request: ICenterDistrictsRequest): Promise<IDistrict[] | null> => {
  try {
    const response = await customFetchWithRetry(`/org/${request.orgId}/district?${constructQueryParamsFromObject(request)}`, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getOrgStatistics = async (payload: IOrgStatisticsRequest): Promise<IOrgStatistics | null> => {
  try {
    const response = await customFetchWithRetry(`/org/${payload.orgId}/statistics?${constructQueryParamsFromObject(payload)}`, {
      method: 'GET'
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    const errorText = await response.text();
    throw new FetchError(`Error fetching user statistics. ${errorText}`, response.status);
  } catch (e: any) {
    throw new FetchError(e.message || e, 500);
  }
};
