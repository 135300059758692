import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { enableScreens } from 'react-native-screens';

import { store, persistedStore } from '../redux/store';

import { App } from 'src/App';
import "../utils/configureAmplify";

// Optimize React Navigation: https://reactnavigation.org/docs/react-native-screens/
enableScreens();

export const RootApp = () => {

    return (
        <Provider store={store}>
            <PersistGate persistor={persistedStore}>
                <App />
            </PersistGate>
        </Provider>
    )
};